import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { makeStyles } from '@material-ui/styles';
import { Error, Warning } from '@material-ui/icons';
import type { Theme } from '@material-ui/core';

import { AlertTooltip } from 'components/AlertTooltip';
import { isExpired, isSoonToBeExpired, segmentDaysRemaining } from 'utils/expiryDates';

import {
  audienceExpiredText,
  audienceSoonWillBeExpiredText,
  segmentArchivedText,
  segmentExpiredText,
  segmentSoonWillBeExpired,
} from '../../constants';

type Segments = {
  expiryDate?: string | null;
  segmentStatus?: string | null;
  isArchived?: boolean | null;
}[];

interface Props {
  name: string;
  pathTo: string;
  status: string;
  estimatedExpiryDate: number | null;
  segments: Segments;
  fromDeal?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  marginRight: {
    marginRight: '5px',
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

export const AudienceCell = memo(
  ({ name, pathTo, status, estimatedExpiryDate, segments = [], fromDeal = false }: Props) => {
    const { flex, link, marginRight } = useStyles();

    const showExpiredAudeinceAlert = isExpired(status);
    const showAudienceExpireSoonAlert = isSoonToBeExpired(estimatedExpiryDate, status);

    const showExpiredSegmentAlert = segments.some(({ segmentStatus }) => isExpired(segmentStatus));
    const showSegmentExpireSoonAlert = segments.some(({ expiryDate, segmentStatus }) =>
      isSoonToBeExpired(expiryDate, segmentStatus),
    );
    const segmentDaysRemain = segmentDaysRemaining(segments);

    const showArchivedAlert = segments.some(segment => segment.isArchived);
    const showExpiredAlert = showExpiredSegmentAlert || showExpiredAudeinceAlert;
    const showExpireSoonAlert = showSegmentExpireSoonAlert || showAudienceExpireSoonAlert;

    const archivedText = segmentArchivedText(true);

    const expiredText = `${showExpiredAudeinceAlert ? audienceExpiredText : ''} ${
      showExpiredAudeinceAlert && showExpiredSegmentAlert ? '\n\n' : ''
    }${showExpiredSegmentAlert ? segmentExpiredText(fromDeal) : ''}`;

    const soonWillBeExpiredText = `${showAudienceExpireSoonAlert ? audienceSoonWillBeExpiredText : ''} ${
      showAudienceExpireSoonAlert && showSegmentExpireSoonAlert ? '\n\n' : ''
    } ${showSegmentExpireSoonAlert ? segmentSoonWillBeExpired(fromDeal, segmentDaysRemain) : ''}`;

    return (
      <div className={flex}>
        <p className={marginRight} data-test="row-title">
          <Link to={pathTo} className={link}>
            {name}
          </Link>
        </p>
        {showArchivedAlert && (
          <AlertTooltip color="#D83158" title={archivedText} icon={<Error fontSize="small" color="inherit" />} />
        )}
        {showExpiredAlert && (
          <AlertTooltip color="#D83158" title={expiredText} icon={<Error fontSize="small" color="inherit" />} />
        )}
        {showExpireSoonAlert && (
          <AlertTooltip
            color="#E8B046"
            title={soonWillBeExpiredText}
            icon={<Warning fontSize="small" color="inherit" />}
          />
        )}
      </div>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
