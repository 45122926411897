import React from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemText, List, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { ExternalLink } from './ExternalLink';

const useStyles = makeStyles(theme => ({
  tabs: {
    height: '100%',
  },
  tabsFlexContainer: {
    borderBottom: 'none',
    height: '100%',
    padding: 0,
    alignItems: 'flex-start',
  },
  tabsIndicator: {
    height: 2,
    backgroundColor: 'unset',
  },
  listItemWrapper: {
    '&:not(:last-child)': {
      marginBottom: 32,
    },
  },
  listItem: {
    margin: '0 !important',
    padding: '0 !important',
  },
  listHeader: {
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: 3,
    marginBottom: 8,
    fontFamily: 'Open Sans',
    fontWeight: 600,
    color: theme.palette.primary.main,
    lineHeight: 1.5,
  },
  item: {
    padding: 0,
  },
  itemLink: {
    display: 'block',
    width: '100%',
    color: theme.palette.text.primary,
    '&, span': {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    '&:hover, &.active': {
      color: theme.palette.primary.main,
    },
  },
}));

export interface MenuItemsTypes {
  label: string;
  path: string;
  isInternal?: boolean;
  isAuthenticated?: boolean;
}

export interface MenuTypes {
  general: Array<MenuItemsTypes>;
  administration: Array<MenuItemsTypes>;
  dashboard: Array<MenuItemsTypes>;
  'reporting & doc': Array<MenuItemsTypes>;
}

interface SideMenuItem {
  label: string;
  path: string;
  isInternal?: boolean;
  isAuthenticated?: boolean;
}

export type SideMenuItems = SideMenuItem[];

export interface SideMenuProps {
  menuItems: MenuTypes;
  className?: string;
  token?: string;
}

const menuItemsOrder = ['general', 'inventory', 'administration', 'dashboard', 'reporting & doc', 'publisher'];

export function SideMenu(props: SideMenuProps): JSX.Element {
  const location = useLocation();
  const classes = useStyles();
  const { menuItems, token } = props;

  return (
    <>
      {menuItemsOrder.map(menu => {
        if (!menuItems[menu] || menuItems[menu].length === 0) return null;
        return (
          <div key={menu} className={classes.listItemWrapper} data-test="menu-section">
            <h5 className={classes.listHeader} data-test="section-title">
              {menu}
            </h5>
            <List className={classes.listItem}>
              {menuItems[menu].map(({ label, path, isInternal = true, isAuthenticated = false }) => {
                const isActive = location.pathname === path;
                return (
                  <ListItem key={path} className={classes.item} data-test="menu-item">
                    {isInternal ? (
                      <Link to={path} className={`${classes.itemLink} ${isActive ? 'active' : ''}`}>
                        <ListItemText primary={label} />
                      </Link>
                    ) : (
                      <ExternalLink to={isAuthenticated ? `${path}?token=${token}` : path} label={label} key={path} />
                    )}
                  </ListItem>
                );
              })}
            </List>
          </div>
        );
      })}
    </>
  );
}
