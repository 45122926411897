import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSession } from 'modules/auth/AuthProvider';
import { isEmpty } from 'lodash';
import { usePermissionsCheck, isAdmin, isUserAclFlagSet, isAccountAclFlagSet, isInternalUser } from 'permissions';
import { fetchAuthToken } from 'firebaseIntegration';
import { Provider } from './context';
import { MenuItemsTypes, MenuTypes } from './types';

const featurePublisherMarketplaceFlag = 'feature.publisher_marketplace_view.enabled';

const getDefaultTopMenuItems = (isAdmin: boolean): Array<MenuItemsTypes> => [
  {
    label: 'Deals',
    path: '/deals',
  },
  {
    label: 'Packages',
    path: isAdmin ? '/inventory/packages/curated' : '/inventory/packages',
  },
  {
    label: 'Audiences',
    path: '/audiences',
  },
  {
    label: 'Segments',
    path: '/segments',
  },
  {
    label: 'OpenXSelect Taxonomy',
    path: '/taxonomy',
  },
];

const adminTopMenuItems: Array<MenuItemsTypes> = [
  {
    label: 'Settings',
    path: '/settings',
  },
];

const reportingTopMenuItems: Array<MenuItemsTypes> = [
  {
    label: 'Docs',
    path: '/docs/openxselect/oxs-main/index.html',
    isInternal: false,
    isAuthenticated: true,
  },
];

export const SigviewViews = {
  CPREF: 'CPREF',
  INVENTORY_DISCOVERY: 'Inventory_Discovery',
} as const;

const SideDrawerProvider = ({ children }) => {
  const [openDrawer, setOpenDrawer] = useState(true);
  const [token, setToken] = useState('');
  const canSeeAdminTabs = usePermissionsCheck([isAdmin]);
  const session = useSession();

  const hasPublisherMarketplace = useMemo(
    () =>
      isAccountAclFlagSet(session, featurePublisherMarketplaceFlag) ||
      isUserAclFlagSet(session, featurePublisherMarketplaceFlag),
    [session],
  );

  useEffect(() => {
    async function getToken() {
      const token = await fetchAuthToken();
      setToken(token ?? '');
    }
    getToken();
  }, []);

  const getTopMenuItems = useCallback(() => {
    const {
      sigview_url,
      account: { exchange_account_partner_id },
    } = session;

    const hasPartnerId = !isEmpty(exchange_account_partner_id);

    const isSigviewEnabled = sigview_url && hasPartnerId;

    const reportingMenuItems = [
      ...(isSigviewEnabled
        ? [
            {
              label: 'Reporting',
              path: sigview_url ? `${sigview_url}&view=${SigviewViews.CPREF}` : '',
              isInternal: false,
            },
          ]
        : []),
      ...reportingTopMenuItems,
    ];

    const inventoryMenuItems = [
      ...(isSigviewEnabled && isInternalUser(session)
        ? [
            {
              label: 'Inventory Discovery',
              path: sigview_url ? `${sigview_url}&view=${SigviewViews.INVENTORY_DISCOVERY}` : '',
              isInternal: false,
            },
          ]
        : []),
    ];

    const adminMenuItem = [
      {
        label: 'Organizations',
        path: '/organizations',
      },
      {
        label: 'Providers',
        path: '/providers',
      },
      {
        label: 'Users',
        path: '/users',
      },
    ];

    const dashboardMenuItem = [
      {
        label: 'Deal Insights',
        path: '/deal-insights',
      },
    ];

    const marketplaceMenuItems = [
      {
        label: 'Publishers Marketplace',
        path: '/publisher-marketplace',
      },
    ];

    const menuItems: MenuTypes = {
      general: getDefaultTopMenuItems(isAdmin(session)),
      inventory: inventoryMenuItems,
      administration: adminTopMenuItems,
      dashboard: dashboardMenuItem,
      'reporting & doc': reportingMenuItems,
    };

    if (canSeeAdminTabs) {
      menuItems.administration = [...adminMenuItem, ...adminTopMenuItems];
    }

    if (hasPublisherMarketplace) {
      menuItems.publisher = marketplaceMenuItems;
    }

    const canSeeDealHealthDashboard =
      isUserAclFlagSet(session, 'feature.deal_health_dashboard.enabled') ||
      isAccountAclFlagSet(session, 'feature.deal_health_dashboard.enabled') ||
      isInternalUser(session);

    menuItems.dashboard = canSeeDealHealthDashboard ? dashboardMenuItem : [];

    return menuItems;
  }, [canSeeAdminTabs, session, hasPublisherMarketplace]);

  const handleDrawerOpen = useCallback(() => {
    setOpenDrawer(prev => !prev);
  }, []);

  return <Provider value={{ openDrawer, handleDrawerOpen, menuItems: getTopMenuItems(), token }}>{children}</Provider>;
};

export default SideDrawerProvider;
